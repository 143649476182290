<template>
  <section>
    <div class="col-md-12 col-sm-1">
      <multi-select v-model="setdata" tag-placeholder="อำเภอ/เขต" :custom-label="nameWithLang"
                    placeholder="อำเภอ/เขต" label="name" track-by="amphur_name"  :options="data"
                    @input="update" style="font-size: 12px"
      ></multi-select>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "amphurs",
  data(){
    return{
      data:[],
      setdata:[],
    }
  },
  props: {
    remove: {
      type: Array,
      default: () => []
    },
    editAmphurs: {
    // type: String | Number | Boolean | Object | {Number, String, Object},
      type: String,
      default: ''
      // type: String
    },
  },
  watch: {
    remove: function() {
      this.setdata = []
    },
    editAmphurs:function () {
      let data = {
        'amphur_name' : this.editAmphurs
      }
      axios.post('api/sysdata/findAmphurs',data)
          .then(response => {
            this.setdata = response.data
          });
    }
  },
  computed:{

  },
  mounted(){
    this.getResults()
  },
  methods:{
    async getResults(){
      await axios.get('api/sysdata/getAmphurs')
          .then(response => {
            this.data =response.data
          });
    },

    nameWithLang({amphur_name}){
      return `${amphur_name}`
    },

    update(data){
      if (data != null) {
        this.$emit("SetData", data.amphur_name);
      }
    },

  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
